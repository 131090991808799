import {  FaLinkedin } from 'react-icons/fa';
import logo from "../../assets/images/devyoh.webp"
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa6';

const AppFooter = () => {
	return (
		<>
			<footer className="footer lg:p-10 p-5 bg-primary-dark/30 text-white">
				<aside className="items-center lg:grid-flow-col m-auto text-center">
					<img src={logo} width={"50px"} height="50px" className=' bg-cover bg-center lg:mr-5 m-auto' alt="logo devyoh" />
				</aside>
				<nav className='m-auto text-center'>
					<h6 className="footer-title text-primary-light">Liens utiles</h6>
					<ul className='m-auto'>
						<li>
							<Link to="/" className="link link-hover">Accueil</Link>
						</li>
						<li>
							<Link to="/services" className="link link-hover">Services</Link>
						</li>
						<li>
							<Link to="/conseils" className="link link-hover">Conseils</Link>
						</li>
						<li>
							<Link to="/offres" className="link link-hover">Offres</Link>
						</li>
						<li>
							<Link to="/contact" className="link link-hover">Contact</Link>
						</li>
					</ul>
				</nav>
				<nav className='m-auto text-center'>
					<h6 className="footer-title text-center m-auto text-primary-light">Légal</h6>
					<ul>
						<li>
							<Link to="conditions-générales-de-vente" className="link link-hover">CGV</Link>
						</li>
						<li>
							<Link to="protection-des-données" className="link link-hover">RGPD</Link>
						</li>
						<li>
							<Link to="mentions-légales" className="link link-hover text-white">Mentions légales</Link>
						</li>
						<li>
							<Link to="politique-des-cookies" className="link link-hover">Politique des cookies</Link>
						</li>
						<li>
							<Link to="politique-de-confidentialité" className="link link-hover">Politique de confidentialité</Link>
						</li>
					</ul>
				</nav>
				<nav className="m-auto">
					<ul className="grid grid-flow-col gap-4">
						<li>
							<Link to="https://www.instagram.com/web_devyoh/" target='_blanck' rel=" noreferrer"className='hover:text-pink-800' >
								<FaInstagram size={"30px"} />
							</Link>
						</li>
						<li>
							<Link to="https://linkedin.com/company/devyoh" target='_blanck' rel=" noreferrer"className='hover:text-blue-900'>
								<FaLinkedin size={"30px"} />
							</Link>
						</li>
					</ul>
				</nav>
			</footer>
		</>

	);
};

export default AppFooter;
