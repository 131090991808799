// CookiesModal.jsx
import {React, useEffect} from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Remplacez '#root' par le sélecteur de votre élément racine

const CookiesModal = ({ isOpen, onRequestClose, onAcceptCookies, onAcceptAllCookies }) => {
    useEffect(() => {
        if (isOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'visible';
        }
    
        // Nettoyer la classe lors de la fermeture de la modale
        return () => {
          document.body.style.overflow = 'visible';
        };
      }, [isOpen]);
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="z-50 lg:w-2/3 w-5/6 h-auto shadow outline-none absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            overlayClassName="z-50 fixed top-0 left-0 w-full h-full bg-primary-dark/90 flex justify-center items-center"
            shouldCloseOnOverlayClick={false} // Empêche la fermeture en cliquant sur l'overlay
        >
            {/* Contenu de la modale de validation des cookies */}
            <div className=" bg-white p-4 rounded flex flex-col w-full h-full m-auto text-center relative l">
                <h3 className="text-2xl font-bold mb-4 text-primary-dark uppercase">
                    Consentement aux cookies
                </h3>
                <h4 className='text-lg mb-5 text-primary-dark'>Bienvenue sur notre site ! 🍪</h4>
                <div className='grid grid-cols-2 text-primary-dark md:grid-cols-2'>
                    <p className='col-span-2 text-base lg:w-2/3 w-5/6  m-auto my-2'>
                        Nous utilisons des cookies pour personnaliser votre expérience, comprendre comment vous utilisez notre site, et vous offrir le meilleur service possible.
                    </p>
                    <p className='col-span-2 text-base lg:w-2/3 w-5/6 m-auto my-2'>
                        Pas d'inquiétude, ces cookies sont inoffensifs et respectueux de votre vie privée.
                    </p>
                    <p className="col-span-2 text-xs md:text-lg text-primary-dark m-auto my-10">
                        En continuant à utiliser notre site, vous acceptez l'utilisation des cookies.
                    </p>
                </div>
                <div className='container m-auto grid grid-cols-1 md:grid-cols-2 gap-y-3'>
                    <button
                        className="col-span-1 z-50 bg-primary-dark text-white px-4 py-2 rounded hover:bg-primary-dark/80 mx-auto w-full md:w-5/6 lg:w-2/3"
                        onClick={onAcceptAllCookies}
                    >
                        Accepter l'ensemble des cookies
                    </button>
                    <button
                        className="col-span-1 z-50 bg-primary-dark text-white px-4 py-2 rounded hover:bg-primary-dark/80 mx-auto w-full md:w-5/6 lg:w-2/3"
                        onClick={onAcceptCookies}
                    >
                        Accepter seulement les cookies nécessaires
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CookiesModal;
