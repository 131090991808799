import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";
import AppFooter from "./components/footer/AppFooter.jsx";
import AppHeader from "./components/header/AppHeader.jsx";
import "./styles/App.css";
import UseScrollToTop from "./hooks/useScrollToTop";
import CookiesModal from "./components/modal/modalCookies.jsx";
import "./"


const Contact = lazy(() => import("./pages/Contact.jsx"));
const Home = lazy(() => import("./pages/Home"));
const NotFound = lazy(() => import("./pages/NotFound.jsx"));
const Website = lazy(() => import("./pages/Services.jsx"));
const Choices = lazy(() => import("./pages/Choices.jsx"));
const Templates = lazy(() => import("./pages/Templates.jsx"));
const TemplateEco = lazy(() => import("./pages/TemplateEco.jsx"));
const LegalMentions = lazy(() => import("./pages/Legals.jsx"));
const RGPD = lazy(() => import("./pages/rgpd.jsx"));
const CGV = lazy(() => import("./pages/Cgv.jsx"));
const Cookie = lazy(() => import("./pages/Cookies.jsx"));
const Confidential = lazy(() => import("./pages/Confidentialité.jsx"));
const Rating = lazy(() => import("./pages/Rating.jsx"));

function App() {
  // localStorage.clear();


  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  
  const closeModal = () => {
    setShowCookiesModal(false);
  };


  const acceptAllCookies = () => {
    setCookiesAccepted(true);
    localStorage.setItem("cookiesAccepted", "true");
    closeModal();
    // Autres actions si nécessaires
    console.log("L'ensemble des cookies ont été acceptés");
  };
  
  const acceptNecessaryCookies = () => {
    // Logique pour accepter uniquement les cookies nécessaires
    setCookiesAccepted(true); // Mise à jour en fonction de votre logique
    localStorage.setItem("cookiesAccepted", "true");
    closeModal();
    console.log("Seulement les cookies nécessaires ont été acceptés");
  };

  useEffect(() => {
    // localStorage.clear();

    const cookiesAcceptedFromStorage = localStorage.getItem("cookiesAccepted");
    setCookiesAccepted(cookiesAcceptedFromStorage === "true");

    // Condition pour afficher la modale
    if (cookiesAcceptedFromStorage === null) {
      setShowCookiesModal(true);
    }
  }, [cookiesAccepted]);

  return (
    <>
      <div className="bg-primary-dark">
        <Router>
          <ScrollToTop className="hidden" />
          <AppHeader />
          <Routes>
            <Route path="/" element={<Suspense fallback={<div>Loading...</div>}><Home /></Suspense>} />
            <Route path="services" element={<Suspense fallback={<div>Chargement de la page Services...</div>}><Website /></Suspense>} />
            <Route path="conseils" element={<Suspense fallback={<div>Chargement de la page Besoins...</div>}><Choices /></Suspense>} />
            <Route path="modèles" element={<Suspense fallback={<div>Chargement de la page Modèles...</div>}><Templates /></Suspense>} />
            <Route path="modèles-one-page" element={<Suspense fallback={<div>Chargement de la page Modèles...</div>}><TemplateEco /></Suspense>} />
            <Route path="mentions-légales" element={<Suspense fallback={<div>Chargement de la page des mentions légales...</div>}><LegalMentions /></Suspense>} />
            <Route path="conditions-générales-de-vente" element={<Suspense fallback={<div>Chargement de la page des conditions générales de ventes...</div>}><CGV /></Suspense>} />
            <Route path="politique-des-cookies" element={<Suspense fallback={<div>Chargement de la page de la politique des Cookies...</div>}><Cookie /></Suspense>} />
            <Route path="politique-de-confidentialité" element={<Suspense fallback={<div>Chargement de la page de la politique de Confidentialité...</div>}><Confidential /></Suspense>} />
            <Route path="protection-des-données" element={<Suspense fallback={<div>Chargement de la page des RGPD...</div>}><RGPD /></Suspense>} />
            <Route path="contact" element={<Suspense fallback={<div>Chargement de la page Contact...</div>}><Contact /></Suspense>} />
            <Route path="offres" element={<Suspense fallback={<div>Chargement de la page Tarifs...</div>}><Rating /></Suspense>} />
            <Route path="*" element={<Suspense fallback={<div>Chargement de la page Redirection...</div>}><NotFound /></Suspense>} />
          </Routes>
          <AppFooter />
        </Router>
        {!localStorage.getItem("cookiesAccepted") && (
          <CookiesModal
          isOpen={showCookiesModal}
          onRequestClose={() => setShowCookiesModal(false)}
          onAcceptAllCookies={acceptAllCookies}
          onAcceptCookies={acceptNecessaryCookies}
        />
        )}
      </div>
     
      <UseScrollToTop />
    </>
  );
}

export default App;
