import { useState, useEffect } from 'react';
import { FiChevronUp } from 'react-icons/fi';

const useScrollToTop = () => {
	const [showScroll, setShowScroll] = useState(false);
	const [isDesktop, setIsDesktop] = useState(false);

	const handleResize = () => {
		setIsDesktop(window.innerWidth >= 1024); // Adapter la taille selon ce qui est considéré comme desktop
	};

	useEffect(() => {
		handleResize(); // Appel initial pour définir isDesktop au chargement de la page

		const handleScroll = () => {
			const shouldShowScroll = window.scrollY > 120 && isDesktop;
			setShowScroll(shouldShowScroll);
		};

		window.addEventListener('scroll', handleScroll);
		window.addEventListener('resize', handleResize);

		return function cleanup() {
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
		};
	}, [isDesktop]);

	const backToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<>
			{showScroll && (
				<FiChevronUp
					className="scrollToTop bg-primary-dark text-primary-light border border-primary-light"
					onClick={backToTop}
					style={{
						height: 45,
						width: 45,
						borderRadius: 50,
						right: 50,
						bottom: 50,
						padding: 5,
					}}
				/>
			)}
		</>
	);
};

export default useScrollToTop;
