import { useEffect, useState } from 'react';
import { FiMenu, FiX } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/devyoh.webp';
import { IoMdArrowDropdown } from "react-icons/io";


const AppHeader = () => {

	const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640); // Ajustez la taille en fonction de vos besoins
	const [showOffresSubMenu, setShowOffresSubMenu] = useState(false); // État pour la visibilité du sous-menu Offres
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 640); // Ajustez la taille en fonction de vos besoins
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const toggleOffresSubMenu = () => {
		setShowOffresSubMenu(!showOffresSubMenu);
	};

	function toggleMenu() {
		setShowMenu(!showMenu);
	}

	function closeMenu() {
		setShowMenu(false);
	}

	return (
		<nav
			id="nav"
			className={`sm:container sm:mx-auto h-20 bg-primary-dark ${isSmallScreen ? 'fixed top-0 left-0 h-20 w-full z-30 bg-primary-dark' : ''}`}
		>
			<div className="z-30 max-w-screen-lg xl:max-w-screen-xl flex flex-col sm:flex sm:justify-between sm:items-center pt-6 bg-primary-dark">
				{/* Header menu links and small screen hamburger menu */}
				<div className="flex justify-between items-center px-4 sm:px-0">
					<div>
						<Link to="/" rel="preload" as="image" fetchpriority="high" href={logo} type="image/webp"  >
							<img src={logo} loading='lazy' alt="Dark Logo" width={'50px'} height={'50px'} />
						</Link>
					</div>
					{/* Small screen hamburger menu */}
					<div className="sm:hidden">
						<button
							onClick={toggleMenu}
							type="button"
							className="focus:outline-none"
							aria-label="Hamburger Menu"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								className="h-7 w-7 fill-current text-secondary-light "
							>
								{showMenu ? (
									<FiX className="text-3xl" />
								) : (
									<FiMenu className="text-3xl" />
								)}
							</svg>
						</button>
					</div>
				</div>
				{/* Header links small screen */}
				<ul
					className={
						showMenu
							? 'block m-0 sm:ml-4  sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center lg:shadow-lg shadow-none'
							: 'hidden'
					}
				>
					<li>
						<Link
							to="/services"
							className="block text-left text-lg text-primary-light hover:text-secondary-light sm:mx-4 mb-2 sm:py-2"
							aria-label="page de services de conception de site internet et de logos"
							onClick={closeMenu}
						>
							Services
						</Link>
					</li>
					<li>
						<Link
							to="/conseils"
							className="block text-left text-lg text-primary-light hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
							aria-label="page de tarifs"
							onClick={closeMenu}
						>
							Conseils
						</Link>
					</li>
					<li>
						<Link

							to="/offres"
							className="block text-left text-lg text-primary-light hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
							aria-label="page de tarifs"
							onClick={closeMenu}
						>
							Toutes nos Offres

						</Link>
					</li>
					<li>
						<Link

							to="/modèles-one-page"
							className="block text-left text-lg text-primary-light hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
							aria-label="page de tarifs"
							onClick={closeMenu}
						>
							Offre Économique
						</Link>
					</li>
					<li>
						<Link

							to="/modèles"
							className="block text-left text-lg text-primary-light hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-dark"
							aria-label="page de tarifs"
							onClick={closeMenu}
						>
							Offre Accessible
						</Link>
					</li>
					<li>
						<Link
							to="/contact"
							className="block text-left text-lg text-primary-light  hover:text-secondary-light sm:mx-4 mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light dark:border-secondary-light"
							aria-label="page de contact"
							onClick={closeMenu}
						>
							Contact
						</Link>
					</li>
				</ul>
				{/* Header links large screen */}
				<ul className="font-general-medium relative hidden sm:mt-7 sm:flex sm:p-0 justify-center items-center shadow-lg sm:shadow-none">
					<li>
						<Link
							to="/services"
							className="block text-left text-2xl text-primary-light hover:text-secondary-light hover:underline sm:mx-4 mb-2 sm:py-2"
							aria-label="About Me"
						>
							Services
						</Link>
					</li>
					<li>
						<Link
							to="/conseils"
							className="block text-left text-2xl text-primary-light hover:text-secondary-light hover:underline sm:mx-4 mb-2 sm:py-2"
							aria-label="About Me"
						>
							Conseils
						</Link>
					</li>
					<li>
						<button
							onClick={toggleOffresSubMenu}
							className=" relative flex text-left text-2xl text-primary-light items-center hover:text-secondary-light hover:underline sm:mx-4 mb-2 sm:py-2"
							aria-label="About Me"
						>
							Offres
							<IoMdArrowDropdown size={30} />
						</button>
						{/* Sous-partie Offres */}
						{showOffresSubMenu && (
							<ul className="absolute z-50 flex flex-col px-5 mt-2.5 left-1/3 translate-x-1/4 justify-center items-center  shadow-none">
								<li className='bg-primary-dark hover:bg-primary-dark/95 duration-300 w-full'>
									<Link to="/modèles-one-page" className="w-full h-full block text-center text-lg  text-primary-light hover:text-secondary-light  mb-2 sm:py-2 border-t-2 pt-3 sm:pt-2 sm:border-t-0 border-primary-light " aria-label="Solutions Économiques" onClick={toggleOffresSubMenu}
									>Pack Économique</Link>
								</li>
								<li className='bg-primary-dark hover:bg-primary-dark/95 duration-300 w-full'>
									<Link to="/modèles" className="block text-center text-lg text-primary-light hover:text-secondary-light sm:mx-4 mb-2 sm:py-2" aria-label="Solutions Accessibles" onClick={toggleOffresSubMenu}
									>Pack Accessible</Link>
								</li>
								<li className='bg-primary-dark hover:bg-primary-dark/95 duration-300 w-full'>
									<Link to="/offres" className="block text-center text-lg  text-primary-light hover:text-secondary-light sm:mx-4 mb-2 sm:py-2" aria-label="Toutes les offres" onClick={toggleOffresSubMenu}
									>Nos Tarifs</Link>
								</li>
							</ul>
						)}
					</li>
					<li>
						<Link
							to="/contact"
							className="block text-left text-2xl text-primary-light hover:text-secondary-light hover:underline  sm:mx-4 mb-2 sm:py-2"
							aria-label="Contact"
						>
							Contact
						</Link>
					</li>
				</ul>

				{/* Header right section buttons */}
				<div className="hidden sm:flex justify-between items-center flex-col md:flex-row">
					{/* Theme switcher large screen */}
				</div>
			</div>
		</nav>
	);
};

export default AppHeader;
