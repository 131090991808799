import './styles/index.css';
import App from './App';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from "@material-tailwind/react";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
<ThemeProvider>
      <App />
    </ThemeProvider>
	</React.StrictMode>
);

